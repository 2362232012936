/* eslint-disable max-len */
/* eslint-disable indent */
const hasContext = (/** @type {string} */ctx) => window.contexts.includes(ctx);

import widgetsMgr from 'widgets/widgetsMgr';

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('checkout'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'checkout.adyen' */ './widgets/checkout/AdyenPaymentAccordion').then(d => d.default),
        import(/* webpackChunkName: 'checkout.adyen' */ './widgets/checkout/PaymentAccordionItemAdyenComponent').then(d => d.default),
        import(/* webpackChunkName: 'checkout.adyen' */ './widgets/checkout/PaymentAccordionItemAdyenScheme').then(d => d.default),
        import(/* webpackChunkName: 'checkout.adyen' */ './widgets/checkout/AdyenSavedCardsAccordionItem').then(d => d.default)
    ]).then(deps => {
        const [
            AdyenPaymentAccordion,
            PaymentAccordionItemAdyenComponent,
            PaymentAccordionItemAdyenScheme,
            AdyenSavedCardsAccordionItem
        ] = deps;

        return {
            listId: 'checkout.adyen',
            widgetsDefinition: () => [
                    ['adyenPaymentAccordion', AdyenPaymentAccordion, 'paymentAccordion'],
                    ['paymentAccordionItemAdyenComponent', PaymentAccordionItemAdyenComponent, 'paymentAccordionItem'],
                    ['paymentAccordionItemAdyenScheme', PaymentAccordionItemAdyenScheme, 'paymentAccordionItemAdyenComponent'],
                    ['adyenSavedCardsAccordionItem', AdyenSavedCardsAccordionItem, 'accordionItem']
            ]
        };
    })
});

widgetsMgr.asyncLists.push({
    getLoadingFlag: () => hasContext('orderconfirmation'),
    load: () => Promise.all([
        import(/* webpackChunkName: 'orderconfirmation.widgets' */ 'widgets/checkout/RadioSelector').then(d => d.default),
        import(/* webpackChunkName: 'orderconfirmation.widgets' */ './widgets/forms/ajax/AdyenCharityForm').then(d => d.default)
    ]).then(deps => {
            const [
                RadioSelector,
                AdyenCharityForm
            ] = deps;

        return {
            listId: 'orderconfirmation.adyen',
            widgetsDefinition: () => [
                ['adyenCharityForm', AdyenCharityForm, 'ajaxform'],
                ['radioSelector', RadioSelector, 'inputRadio']
            ]
        };
    })
});
